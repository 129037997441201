




















































.subscriber-video {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
